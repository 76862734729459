"use client";

import React, { useState } from "react";
import classNames from "classnames";
import { AccordionTabContent } from "./AccordionTabContent";
import { CardBox } from "../CardBox";
import { TabsProps } from "./types";

import { Picker } from "../Picker";
import { PickerProps } from "../Picker/types";
import "./styles.scss";

export const Tabs = ({ tabsData, light, className, data, id }: TabsProps) => {
  const classnames = classNames(`tabs`, {
    ["tabs--light"]: light,
    ["tabs--left"]: tabsData[0].position === "left",
    [`${className}`]: className,
  });

  const [selectedOption, setSelectedOption] = useState<string | string[]>(undefined);

  const renderTabByType = (tab) => {
    let tabContent;
    tab.type === "form" ? (tabContent = tab.content as PickerProps) : (tabContent = tab.content);

    switch (tab.type) {
      case "accordion":
        return (
          <>
            {Array.isArray(tab.content) &&
              tab.content.map((item, i) => (
                <AccordionTabContent
                  key={i}
                  item={item}
                  index={i}
                  customId={tab.id}
                />
              ))}
          </>
        );
      case "form":
        return (
          <>
            <div
              className={tabContent.className}
              id={tabContent.id}>
              <Picker
                options={tabContent.options}
                selector={tabContent.selector}
                onClick={setSelectedOption}
              />
            </div>
          </>
        );
      default:
        return (
          <>
            {!Array.isArray(tab.content) ? (
              <p>{tabContent}</p>
            ) : (
              <div>
                {Array.isArray(tab.content) &&
                  tab.content.map((item, i) => (
                    <CardBox
                      item={item}
                      key={i}
                    />
                  ))}
              </div>
            )}
          </>
        );
    }
  };

  return (
    <div
      className={classnames}
      data-testid="Tabs">
      {tabsData.map((tab, i) => (
        <React.Fragment key={i}>
          <input
            type="radio"
            name="tabset"
            id={`tab-${tab.id}`}
            aria-controls={tab.label}
            defaultChecked={i == 0}
            className="tabs-input"
          />
          <label
            htmlFor={`tab-${tab.id}`}
            className={`tabs-label ${tab.style ? "tab-label--rounded" : ""}`}>
            {tab.label}
            <span className="subLabel"> {tab.subLabel}</span>
          </label>
        </React.Fragment>
      ))}

      <div className="tab-panels">
        {tabsData?.map((tab, i) => {
          return (
            <section
              id={`tab-panel-${i}`}
              className="tab-panel"
              key={i}>
              <>{renderTabByType(tab)}</>
            </section>
          );
        })}
      </div>
    </div>
  );
};
